import React, { FunctionComponent, Fragment } from "react"
import { IndexPageHeader } from "../../../../types/page"
import { ScriptureList } from "../scripture-list/scripture-list"
import { IndexHeader } from "../../../../components/index-header/index-header"

export interface ScripturesPageProps {
  pageHeader: IndexPageHeader
}

export const ScripturesPage: FunctionComponent<ScripturesPageProps> = ({
  pageHeader
}) => (
  <Fragment>
    <IndexHeader header={pageHeader} />
    <ScriptureList />
  </Fragment>
)
