import { useContext, useEffect } from "react"
import { Maybe, ScripturesPageQuery } from "../../../../graphql/types"
import { generateScripturesData } from "../mappers/generate-scriptures-data"
import { ScripturesContext } from "../scriptures-context"

export const useScripturesData = (data: Maybe<ScripturesPageQuery>): void => {
  const { setScriptures } = useContext(ScripturesContext)

  useEffect(() => {
    if (data) {
      const scriptures = generateScripturesData()

      setScriptures(scriptures)
    }
  }, [data, setScriptures])
}
