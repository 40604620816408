import { books } from "../scripture-constants"
import { Book, Volume, Chapter } from "../scripture-types"

const volumeNames = {
  1: "old-testament",
  2: "new-testament"
}

const getChaptersByBook = (bookID: string): Chapter[] => {
  const book = books.find((book) => book.id === bookID)!

  return Array.from(Array(book.chapters).keys()).map((key) => ({
    chapter: key + 1,
    slug: `${book.id}-${key + 1}` // TODO: Remove this - it can be derived on the fly. I'm not sure we need those intermediary types at all.
  }))
}

export const generateScripturesData = (): Volume[] => {
  const volumes: Volume[] = [
    {
      title: volumeNames[1],
      books: []
    },
    {
      title: volumeNames[2],
      books: []
    }
  ]

  books.forEach((book) => {
    const newBook: Book = {
      slug: book.id,
      title: book.id, // TODO: Remove title - it needs to be localised anyway,
      chapters: getChaptersByBook(book.id)
    }

    volumes[book.volume - 1].books.push(newBook)
  })

  return volumes
}
