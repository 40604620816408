import { ModalPosition } from "../../../../components/modal/modal"

export type Dimensions = {
  height: number
  width: number
}

export const calculateModalPosition = (
  parentRect: DOMRect,
  modalDimensions: Dimensions,
  documentDimensions: Dimensions
): ModalPosition => {
  let modalX = parentRect.width / 2 - modalDimensions.width / 2
  let modalY = parentRect.height / 2 - modalDimensions.height / 2

  if (parentRect.left + modalX < 0) {
    modalX = 0
  }

  if (parentRect.left + modalDimensions.width > documentDimensions.width) {
    modalX = documentDimensions.width - modalDimensions.width - parentRect.left
  }

  if (parentRect.top + modalY < 0) {
    modalY = 0
  }

  if (parentRect.top + modalDimensions.height > documentDimensions.height) {
    modalY = documentDimensions.height - modalDimensions.height - parentRect.top
  }

  return {
    x: modalX,
    y: modalY
  }
}
