import React, { FunctionComponent } from "react"
import { FormattedMessage } from "react-intl"
import { Chapters } from "../chapters/chapters"
import { useScriptures } from "../../hooks/use-scriptures"
import { PathPrefix } from "../../../../core/constants"
import { getScriptureTranslationId } from "../../scripture-utils"
import styles from "./scripture-list.module.scss"

export const ScriptureList: FunctionComponent = () => {
  const { scriptures } = useScriptures()

  return (
    <div className={styles.scripturesList}>
      {scriptures.map(({ books, title }, index) => (
        <div className={styles.scripture} key={index}>
          <h2>
            <FormattedMessage id={getScriptureTranslationId(title)} />
          </h2>
          <div className={styles.books}>
            {books.map((book) => {
              return (
                <div className={styles.book} key={book.slug}>
                  <Chapters
                    title={book.title}
                    chapters={book.chapters}
                    url={`${PathPrefix.Scriptures}/${book.slug}`}
                  />
                </div>
              )
            })}
          </div>
        </div>
      ))}
    </div>
  )
}
