import React, { FunctionComponent } from "react"
import { graphql } from "gatsby"
import { ScripturesPageQuery } from "../../../graphql/types"
import { useIndexPageData } from "../../hooks/use-index-page-data"
import { PageProps } from "../../types/page"
import { ScripturesPage } from "../../features/scriptures/components/scriptures-page/scriptures-page"
import { useScripturesData } from "../../features/scriptures/hooks/use-scriptures-data"
import { Page } from "../../components/page/page"
import { PageBreadcrumbs } from "../../core/breadcrumbs"
import { PageOpenGraphImage } from "@core/constants"

export const ScripturePage: FunctionComponent<
  PageProps<ScripturesPageQuery>
> = ({ data = null, ...props }) => {
  const { header, metadata } = useIndexPageData("scriptures")

  useScripturesData(data)

  return (
    <Page
      {...props}
      metadata={{
        ...metadata,
        openGraphImage: PageOpenGraphImage.Scripture
      }}
      pageOptions={{
        headerVariant: "cream",
        breadcrumbs: PageBreadcrumbs.Scriptures
      }}
    >
      <ScripturesPage pageHeader={header} />
    </Page>
  )
}

export default ScripturePage

export const query = graphql`
  query ScripturesPage {
    allScriptureReference {
      ...ScripturesList
    }
  }
`
