import React, {
  FunctionComponent,
  useRef,
  useState,
  useCallback,
  useLayoutEffect,
  useEffect
} from "react"
import classNames from "classnames"
import { FormattedMessage } from "react-intl"
import { Link } from "../../../../components/link/link"
import { Modal, ModalPosition } from "../../../../components/modal/modal"
import { Chapter } from "../../scripture-types"
import { books } from "../../scripture-constants"
import { PathPrefix } from "../../../../core/constants"
import { getScriptureTranslationId } from "../../scripture-utils"
import { calculateModalPosition } from "./chapters-helpers"
import styles from "./chapters.module.scss"
import {
  mainSearchIndexName,
  searchClient
} from "@features/search/search-constants"
import { Skeleton } from "@components/skeleton/skeleton"

export interface ChaptersProps {
  buttonClassName?: string
  chapters: Chapter[]
  title: string
  url: string
}

export const Chapters: FunctionComponent<ChaptersProps> = ({
  buttonClassName,
  chapters,
  title,
  url
}) => {
  const [isModalOpen, setModalOpen] = useState<boolean>(false)
  const [modalPosition, setModalPosition] = useState<ModalPosition>({
    x: 0,
    y: 0
  })

  const modalRef = useRef<HTMLDivElement>(null)
  const buttonRef = useRef<HTMLButtonElement>(null)

  const handleButtonClick = useCallback(() => {
    setModalOpen(true)
  }, [])

  useLayoutEffect(() => {
    if (isModalOpen) {
      const buttonBounds = buttonRef.current!.getBoundingClientRect()
      const modalBounds = modalRef.current!.getBoundingClientRect()

      const modalPosition = calculateModalPosition(
        buttonBounds,
        { width: modalBounds.width, height: modalBounds.height },
        { width: document.body.offsetWidth, height: document.body.offsetHeight }
      )

      setModalPosition(modalPosition)
    }
  }, [isModalOpen])

  const handleModalClose = useCallback(() => {
    setModalOpen(false)
  }, [])

  const book = <FormattedMessage id={getScriptureTranslationId(title)} />
  const chapterCount = books.find((book) => book.id === title)?.chapters

  if (chapterCount === undefined) {
    return null
  }

  const searchResultsIndex = searchClient.initIndex(mainSearchIndexName)

  const Chapter: FunctionComponent<{ num: number }> = ({ num }) => {
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [haveSearchResults, setSearchResults] = useState<boolean>(false)

    const chapterFilter = `fields.primaryScriptureReference.start.book:${title} AND fields.primaryScriptureReference.start.chapter:${num}`
    const bookFilter = `fields.entireBookReference.fields.book:${title}`

    useEffect(() => {
      searchResultsIndex
        .search("", {
          filters: `${chapterFilter} OR ${bookFilter}`
        })
        .then(({ hits }) => {
          if (hits && hits.length) {
            setSearchResults(true)
          }

          setIsLoading(false)
        })
    }, [haveSearchResults, chapterFilter, bookFilter])

    const haveChapters =
      chapters.find((chapter) => chapter.chapter === num) && haveSearchResults

    return (
      <div className={styles.chapter}>
        {isLoading ? (
          <Skeleton dark width="30px" height="30px" />
        ) : haveChapters ? (
          <Link
            to={`${PathPrefix.Scriptures}/${title}-${num}`}
            className={styles.link}
          >
            {num}
          </Link>
        ) : (
          <span className={styles.chapterNumber}>{num}</span>
        )}
      </div>
    )
  }

  return (
    <div className={styles.wrapper}>
      <button
        className={classNames(styles.button, buttonClassName)}
        onClick={handleButtonClick}
        ref={buttonRef}
      >
        {book}
      </button>
      {isModalOpen && (
        <Modal
          onClose={handleModalClose}
          className={styles.chaptersModal}
          position={modalPosition}
          ref={modalRef}
          withTint={false}
        >
          <div className={styles.chapters}>
            <Link className={styles.title} to={url}>
              <FormattedMessage id={getScriptureTranslationId(title)} />
            </Link>
            <div className={styles.content}>
              {[...Array(chapterCount)].map((_, i: number) => (
                <Chapter num={i + 1} key={`book_${title}_chapter_${i + 1}`} />
              ))}
            </div>
          </div>
        </Modal>
      )}
    </div>
  )
}
